@import "../../theme/breakpoints";

.profile-photo {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: -4em;
  ion-avatar {
    width: 7rem;
    height: 7rem;
    box-shadow: 0 0 10px rgba(var(--ion-color-medium-rgb), 0.3);
    img {}
    z-index: 9;
  }

  ion-avatar + ion-avatar {
    z-index: 1;
    transform: translateX(-3.5rem);
    margin-right: -3.5rem;
  }
}