@import "../../theme/breakpoints";

$content-border-radius: 0;

.main-layout {
  @include lg {
    background: var(--ion-color-primary);
  }

  :host(ion-content) .transition-effect {
    top: -8rem !important;
    bottom: 0 !important;
    height: auto !important;
  }

  ion-content::part(background)::before {
    content: '';
    position: absolute;
    left: 0;
    top: -8rem;
    bottom: 0;
    right: 0;
    background-size: cover;
    z-index: -5;
    @include lg {
      display: none;
    }
  }

  .header-md:after {
    display: none;
  }

  ion-content {
    --background: none;
    position: relative;

    &::part(background)::after {
      content: '';
      display: block;
      position: absolute;
      top: 60%;
      bottom: -1000px;
      left: 0;
      right: 0;
      background: var(--ion-color-white);
    }

    @include lg {
      &::part(background)::after {
        display: none;
      }
      &::part(scroll)::before {
        content: '';
        display: block;
        position: fixed;
        max-height: none;
        right: 50%;
        bottom: 0;
        top: 0;
        left: max(var(--zero-px), calc((100vw - #{$desktop-max-width}) / 2));
        background: url('../../theme/images/background-main.jpg') no-repeat center center;
        background-size: cover;
        z-index: -5;
      }
    }
  }

  ion-header {
    //noinspection ALL
    .header::before {
      content: '';
      position: absolute;
      left: 0;
      top: calc(-1 * constant(safe-area-inset-top));
      top: calc(-1 * env(safe-area-inset-top));
      bottom: 0;
      right: 0;
      opacity: 0;
      transition: opacity 0.25s ease-in-out;
      background: rgba(255, 255, 255, 0.75);
      z-index: -2;
    }

    &.shadow {
      .header::before {
        opacity: 1.0;
      }
    }

    @include lg {
      position: sticky;
      background: var(--ion-color-white);
      box-shadow: 0 0 8px var(--ion-color-dark);
      display: flex;
      flex-direction: column;
      .header {
        flex: 1;
        margin: 0 5rem;
        align-self: center;

        &::before {
          display: none;
        }
      }
    }
  }

  .scroll-container {
    display: flex;
    flex-direction: column;
    min-height: 100%;
    overflow: hidden;
    position: relative;
    @include lg {
      padding-left: calc((100vw - 100%) / 2);
      max-width: #{$desktop-max-width};
      width: 100%;
      margin: 0 auto;
      overflow: initial;
    }

    &::after {
      content: '';
      display: block;
      flex-grow: 1;
      background: var(--ion-color-white);
    }

    &.scrolled {
      .before-content {
        z-index: -5;
      }
    }

    @include lg {
      flex-direction: row;
      &::after {
        display: none;
      }
      .before-content {
        position: sticky;
        flex-basis: 50%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        text-align: left;
        padding: 4rem 3rem 2rem 6rem;

        .slide-content {
          position: sticky;
          left: 0;
          top: 6rem;

          .slide-title {
            font-size: 32px;
            font-weight: 600;
            margin-bottom: 2rem;
          }

          .slide-text {
            font-size: 24px;
          }

          ion-button {
            font-size: 18px;
            height: 3rem;
            min-width: 10rem;
            margin-top: 2rem;
          }
        }
      }
      .content-container {
        flex: 1;
        margin: 2rem 6rem 0;
      }
    }
  }

  .content-container {
    position: relative;
    transform: translate3d(0, 0, 0);
    flex-shrink: 0;
    padding: 2rem 0 0;
    min-height: $content-border-radius;
    display: flex;
    flex-direction: column;

    & > ion-grid {
      width: 100%;
    }

    &::before {
      transform: translate3d(0, 0, 0);
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      border-top-left-radius: $content-border-radius;
      border-top-right-radius: $content-border-radius;
      z-index: -5;
    }
  }

  .before-content {
    padding: 0;
  }

  h1 {
    margin-top: 0;
  }

  @media (max-width: $screen-lg-min - 1px) {
    .content-container {
      min-height: 100%;
      justify-content: center;
    }
    .scroll-container {
      overflow: auto;
      height: 100%;
    }
  }
}