@import '../../../ui/theme/breakpoints';

@mixin svg-icon($icon, $width: 32px, $height: 32px) {
  width: $width;
  height: $height;
  position: relative;
  &::after {
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
    content: '';
    -webkit-mask: $icon no-repeat 50% 50%;
    mask: $icon no-repeat 50% 50%;
    background-color: var(--ion-color-primary);
    -webkit-mask-size: cover;
    mask-size: cover;
    width: $width;
    height: $height;
    @content;
  }
}

.voucher-selector {
  @include lg {
    font-size: 18px;
    h5 {
      font-size: 25px;
    }
  }

  .form-input {
    border-color: var(--ion-color-tertiary);
    color: var(--ion-color-tertiary);
    --placeholder-color: var(--ion-color-tertiary);
    --placeholder-opacity: 1;
    font-size: 16px;
  }

  ion-spinner {
    display: block;
    margin: 2rem auto;
  }

  .voucher-header {
    font-weight: 600;
  }

  .list-title {
    margin-top: 0;
    margin-bottom: 0.65rem;
  }

  .list {
    margin: 0.5rem 0 0 0;
    list-style-type: none;
    padding-left: 0;

    li {
      &:before {
        content: "\2606";
        padding-right: 0.5rem;
        color: var(--ion-color-secondary);
      }
    }
  }
}

.voucher-gallery-header {
  height: calc(18rem + env(safe-area-inset-top, 0));
  @include lg {
    height: 100%;
  }
  .default-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

:root {
  --select-border: var(--ion-color-primary);
  --select-focus: var(--ion-color-primary);
}
