$screen-xs-min: 377px;
$screen-sm-min: 576px;
$screen-md-min: 768px;
$screen-lg-min: 992px;
$screen-xl-min: 1200px;
$screen-xxl-min: 1400px;

$desktop-max-width: 2000px;

@mixin sm {
  @media (min-width: #{$screen-sm-min}) {
    @content;
  }
}
@mixin md {
  @media (min-width: #{$screen-md-min}) {
    @content;
  }
}
@mixin lg {
  @media (min-width: #{$screen-lg-min}) {
    @content;
  }
}
@mixin xl {
  @media (min-width: #{$screen-xl-min}) {
    @content;
  }
}
@mixin xxl {
  @media (min-width: #{$screen-xxl-min}) {
    @content;
  }
}
@mixin max-lg {
  @media (max-width: #{$screen-lg-min - 1}) {
    @content;
  }
}

// ***
// Since web content is displayed only on right half of the screen to
// avoid resetting and duplicating style used combined breakpoints
// ***

@mixin sm-content {
  @media all and  (max-width: #{$screen-xs-min - 1}),
  all and (min-width: #{$screen-lg-min}) and (max-width: #{$screen-xl-min - 1}) {
    @content;
  }
}

@mixin md-content {
  @media all and (min-width: #{$screen-xs-min})  and (max-width: #{$screen-sm-min - 1}),
  all and (min-width: #{$screen-xl-min}) and (max-width: #{$screen-xxl-min - 1}) {
    @content;
  }
}

@mixin lg-content {
  @media all and (min-width: #{$screen-sm-min}) and (max-width: #{$screen-lg-min - 1}),
  all and (min-width: #{$screen-xxl-min}){
    @content;
  }
}

