@import '../../../ui/theme/breakpoints';

.expert-reviews {
  background: var(--ion-color-white);
  padding: .5rem;
  position: relative;
  text-align: center;

  .review {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    margin-top: .5rem;
  }

  .rating-container {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    margin-top: .25rem;
  }

  .review-container {
    width: 100%;
    display: flex;

    .divider {
      flex-grow: 1;
    }

    &.offset-bottom {
      margin-bottom: 1rem;
    }

    .review-author {
      color: var(--ion-color-tertiary);
      margin-right: .5rem;
      font-size: .85rem;
    }

    .review-rating {
      margin-right: .5rem;
    }

    .review-date {
      color: var(--ion-color-tertiary);
      font-size: .85rem;
    }
  }

  .review-content {
    font-size: 1.125rem;
  }

  .review-text-container {
    color: var(--ion-color-tertiary);
    padding: .5rem 1rem 1rem 0;
  }

  .review-icon {
    font-size: 1.2rem;
  }
}

.services-list {
  background: var(--ion-color-white);
  padding: .5rem;
  //border-radius: 1rem;
  box-shadow: 0 0 10px rgba(var(--ion-color-medium-rgb), 0.3);
  position: relative;
  text-align: center;
}

.expert-profile-before-content {
  min-height: 18rem;
  position: relative;
  //noinspection ALL
  .btn-wrapper {
    margin-top: constant(safe-area-inset-top);
    margin-top: env(safe-area-inset-top);
    position: absolute;
    top: 0;
    right: 0;
  }

  .fullscreen-btn {
    position: relative;
    --border-radius: 50% !important;
    --border-width: 0 !important;
    --background-activated: none;
    border-radius: 50%;
    background-color: white;
    opacity: 0.8;
    content: "←";
    color: var(--ion-color-primary);
    min-height: 2rem;
    min-width: 2rem;
    height: 2rem;
    width: 2rem;
    right: 1rem;
    top: 1rem;
    z-index: 999;
    @include lg {
      display: none;
    }
  }

  //noinspection ALL
  .expert-slider {
    height: calc(18rem + constant(safe-area-inset-top));
    height: calc(18rem + env(safe-area-inset-top));

    .swiper-pagination.swiper-pagination-bullets {
      bottom: 6rem;
    }

    @include lg {
      height: 100%;
      z-index: 5;
    }
  }

  //noinspection ALL
  &.modal .expert-slider {
    height: calc(18rem + constant(safe-area-inset-top));
    height: calc(18rem + env(safe-area-inset-top));
  }

  .expert-slide {
    height: 100%;

    img, video {
      object-fit: cover;
      max-width: initial;
      max-height: initial;
      width: 100%;
      height: 100%;
      pointer-events: none;
    }
  }

  @include lg {
    z-index: 100;
  }
}

.expert-profile {
  .info-card {
    background: var(--ion-color-white);
    padding: 1rem 1rem 0;
    margin-top: -6rem;
    position: relative;
    text-align: center;

    button.share {
      position: absolute;
      right: 0.75rem;
      top: 0.5rem;
      width: 2rem;
      height: 2rem;
      text-align: center;
      color: var(--ion-color-primary);
      background: transparent;

      &:focus {
        outline: none;
      }
    }

    h4 {
      font-size: 20px;
      margin: 1rem 0 0.75rem;
    }

    .slogan {
      margin: 0 0 0.5rem;
    }

    .languages {
      & > svg {
        font-size: 20px;
        margin-right: 1rem;
        transform: translateY(2px);
      }

      margin: 1.5rem 0 1.25rem;
    }

    .details-container {
      display: flex;
      flex-direction: row;
      margin: 0 -1rem;
      border-top: 1.5px solid var(--ion-color-white);

      & > .cell {
        flex-basis: 50%;
        margin: 1rem 0;

        &:nth-child(2n) {
          border-left: 1.5px solid var(--ion-color-white);
        }

        .label {
          color: var(--ion-color-secondary);
        }

        .value {
          font-size: 18px;
          font-weight: 600;
        }
      }
    }
  }

  .availability, .services {
    padding-bottom: 5rem;
    margin-right: -3.5rem;
  }

  .availability {
    .availability-list {
      border: solid 1px var(--ion-color-primary);
    }
  }

  .selected-type > .availability-list {
    background: var(--ion-color-primary);
    color: var(--ion-color-white);

    & > ion-item {
      color: var(--ion-color-white);

      & > button.change-treatment-type {
        div, svg {
          color: var(--ion-color-white);

          &::after {
            transition: background-color 0.25s ease-in-out;
            background-color: var(--ion-color-white);
          }
        }
      }
    }

    & > .icon-container {
      div, svg {
        &::after {
          transition: background-color 0.25s ease-in-out;
          background-color: var(--ion-color-white);
        }
      }
    }
  }

  ion-list {
    //border-radius: 1rem;
    padding: 0.5rem 0;

    ion-item {
      color: var(--ion-color-tertiary);
      --background: none;
    }
  }

  .description {
    padding-bottom: 0;
  }

  .services {
    padding-top: 0;

    .services-list {
      border: var(--ion-color-primary) 1px solid;
    }
  }

  .swiper-wrapper {
    display: flex;
    align-items: center;
  }

  .content-container::before {
    background: var(--ion-color-linen);
  }
}

.before-content.fullScreen {
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0;
  z-index: 9999;
  display: flex;
  align-items: center;
  background: gray;

  video {
    object-fit: contain;
  }
}

.change-treatment-type {
  background: none;
  margin-left: auto;
  margin-bottom: 0.33rem;

  &:focus {
    outline: none
  }
;

  svg {
    font-size: 1.35rem;
    display: block;
    color: var(--ion-color-primary);
  }
}

.fullScreenContent {
  z-index: 11;
}
