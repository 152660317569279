.hotel-booking-request {

  .active {
    border-color: var(--ion-color-primary);
  }
  .not-active  {
    .expand-button {
      border-color: var(--ion-color-medium);
      color: var(--ion-color-medium);
    }
  }
}