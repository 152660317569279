@import '../../../ui/theme/breakpoints';
@import "src/ui/elements/ExpertCard/expertCard.module.scss";

.descriptionWrapper {
  position: relative;
}

.descriptionCard {
  background: var(--ion-color-primary);
  justify-content: center;
  display: flex;
  flex-direction: column;
  text-align: center;
  padding: 1.25rem 1.5rem 1rem;
  margin-top: -7.5rem;

  @include lg {
    margin-top: 0;
  }
}

.descriptionHeading {
  font-weight: 600;
  font-size: 1rem;
}

.descriptionContent {
  font-size: 14px;

}

.grid {
  --ion-grid-padding: calc(var(--ion-grid-column-padding) + 5px);
}

.resultCard {
  display: flex;
  flex-direction: row;
  padding: 1rem;
  background: var(--ion-color-primary);

  &.selected {
    background: var(--ion-color-linen);
  }

  //noinspection ALL
  ion-avatar {
    min-width: 68px;
    width: 68px;
    height: 68px;
    @include sm {
      min-width: 72px;
      width: 72px;
      height: 72px;
    }

    &:hover {
      cursor: pointer;
    }
  }

  .expert-info-container {
    padding: 0 0.75rem;
    align-self: center;

    @include sm {
      display: flex;
      flex-direction: column;
      gap: 0.2rem
    }

    @include sm-content {
      flex: initial;
      display: flex;
      flex-direction: column;
    }
  }

  &.active {
    &:hover {
      cursor: pointer;
    }
  }
}


.expertInfo {
  display: grid;
  width: 100%;
  grid-template-areas:
        "name  price"
        "rating  details";
  grid-template-columns: repeat(2, 1fr);
  padding: 0.75rem;
  gap: 0.5rem;
}

.name {
  grid-area: name;
  align-self: self-start;
  font-size: 18px;
  font-weight: 600;
  color: var(--ion-color-black);
}

.price {
  @include price
}