@import '../../../ui/theme/breakpoints';

.custom-modal {
  position: absolute;
  transform: translateX(-50%);
  left: 50%;
  display: flex;
  cursor: initial;
  width: 100%;
  height: 100%;
  @include lg {
    box-shadow: 0 1.75rem 3rem rgba(0, 0, 0, 0.4);
    max-width: 480px;
    max-height: 800px;
  }

  &:focus {
    outline: none;
  }

  .modal-wrapper {
    width: 100%;
    background-color: white;
    display: flex;
    flex-direction: column;
    overflow: auto;

    &.mobile {
      animation: slide-in 375ms;
    }
  }
}

.custom-modal-overlay {
  cursor: pointer;
  position: fixed;
  inset: 0;
  @include lg {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.32);
  }
}

.ReactModal__Content--before-close .modal-wrapper.mobile {
  animation: slide-out 250ms;
}

@keyframes slide-in {
  from {
    transform: translateY(100%);
  }
  to {
    transform: translateY(0)
  }
}

@keyframes slide-out {
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(100%)
  }
}
