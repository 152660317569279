.settings-select {
  &[aria-checked=true] {
    --background: var(--ion-color-tertiary);

    .alert-radio-icon {
      border-color: var(--ion-color-tertiary) !important;
    }

    .alert-radio-inner {
      background: var(--ion-color-tertiary) !important;
    }
  }
}

.button-text {
  color: var(--ion-color-tertiary);
}

.sc-ion-alert-md, .sc-ion-alert-ios{
  color: var(--ion-color-tertiary);
}
