@import '../../../ui/theme/breakpoints';

.locationPage {
  .gallery-header .gallery-img {
    object-position: right;
  }
}

.locations {
  &__list {
    display: inline-block;
  }

  .location-card {
    position: relative;

    &__details {
      position: absolute;
      right: 0.35rem;
      top: 0.35rem;
      width: 2rem;
      height: 2rem;
      background: var(--ion-color-white);
      padding: 1rem;
      color: var(--ion-color-black);
      font-weight: 400;
    }

    img {
      width: 100%;
      height: 8rem;
      object-fit: cover;
    }

    &__caption {
      display: flex;
      flex-direction: row;
      align-items: flex-start;

      & > .caption-text {
        padding-left: 0.25rem;
        flex: 1;

        .name {
          display: block;
          font-size: 20px;
          font-weight: 600;
        }

        .locality {
          display: block;
        }
      }

    }

    &__status {
      margin-top: 0.4rem;
      margin-right: 0.35rem;
      background: var(--ion-color-secondary);
      color: var(--ion-color-secondary-contrast);
      font-weight: 600;
      font-size: 14px;
      padding: 0.65rem 1rem;
    }

    //noinspection ALL
    ion-button {
      padding: 0;
      --padding-bottom: 0;
      --padding-end: 0;
      --padding-start: 0;
      --padding-top: 0;
    }
  }

  ion-button.location-card__details {
    width: fit-content;

    &::part(native) {
      padding: 1rem;
      border: none;
    }
  }
}
