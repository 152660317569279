@import '../../../../ui/theme/breakpoints';

.booking-summary-table {
  background: var(--ion-color-white);
  border: 1px solid var(--ion-color-tertiary);
  padding: 0.25rem 1rem;
  ion-item {
    --border-color: var(--ion-color-white);
    --inner-padding-top: .5rem;
    --inner-padding-bottom: .5rem;
    --padding-start: 0.5rem;
    --detail-icon-color: var(--ion-color-primary);
    --detail-icon-opacity: 1;
    @include lg {
      .code {
        margin-bottom: 0.5rem;
      }
      --inner-padding-top: 0.25rem;
      --inner-padding-bottom: 0.25rem;
    }

    svg {
      display: block;
      position: absolute;
      font-size: 18px;
    }

    svg + .text {
      display: block;
      margin-left: 2.5rem;
    }
  }

  .treatment-icon {
    width: 1.125em;
    height: 1.125em;
  }
}

.booking-summary h4.table-subheading {
  margin: 1.5rem 0;
}