.loader-container {
  display: flex;
  justify-content: center;
}

.info-card {
  background: var(--ion-color-white);
  position: relative;
}

.error-message {
  font-size: .8rem;
  padding-top: 0;
  color: red;
}

.create-review-header {
  font-weight: 600;
  text-align: center;
}
.label {
  font-weight: 600;
}

.rating-field-container {
  display: flex;
  justify-content: center;
  padding-top: 0;
}

.expert-avatar {
  width: 6rem;
  height: 6rem;
  img {
    border-radius: 50%;
    position: relative;
    top: -4rem;
  }
}
.expert-img-container {
  display: flex;
  padding: 0;
  justify-content: center;
  height: 2rem;
}

.submit-button {
  width: 100%;
}

.avatar-wrapper {
  display: flex;
  justify-content: center;
  ion-avatar { z-index: 9}
  ion-avatar + ion-avatar {
    z-index: 1;
    margin-right: -25%;
    transform: translateX(-50%);
  }
}
