@import '../../../../ui/theme/breakpoints';

.treatment-result-card {
  background: var(--ion-color-white);
  box-shadow: 0 0 10px rgba(var(--ion-color-medium-rgb), 0.3);
  padding: 1rem;
  height: 100%;

  ion-avatar {
    width: 72px;
    height: 72px;
  }

  .card-header {
    display: flex;
    flex-direction: row;
  }

  .name-container {
    flex: 1;
    display: flex;
    flex-direction: column;
    padding: 0 0.75rem;
    align-self: center;

    & > .name {
      font-size: 18px;
      font-weight: 600;
    }

    & > .age {
      font-size: 14px;
      margin-top: 0.125rem;
    }
  }

  .submenu {
    position: relative;

    ion-router-link {
      color: #e5402e;
    }

    a {
      display: inline-block;
    }

    button.more-button {
      color: var(--ion-color-tertiary);
      font-size: 18px;
      background: none;
      height: 2.5rem;
      width: 2.5rem;
      margin-top: -0.5rem;
      margin-right: -0.5rem;

      &:focus {
        outline: none
      }
    }
  }

  .description {
    margin: 0.75rem 0;
  }

  .footer {
    display: flex;
    flex-direction: row;

    .no-availabilities-text {
      margin-top: .5rem;
    }

    & > .availabilities-container {
      &::-webkit-scrollbar {
        @include max-lg {
          display: none;
        }
      }

      flex: 1;
      overflow-y: auto;
      display: flex;
      padding-bottom: 0.75rem;

      button {
        background: transparent;
        border: 1.5px solid var(--ion-color-primary);
        color: var(--ion-color-secondary);
        font-size: 18px;
        padding: 0.5rem 1rem;
        margin-right: 0.5rem;
        transition: all ease-in-out 0.25s;

        &:focus {
          outline: none;
        }

        &.selected {
          background: var(--ion-color-primary);
          color: var(--ion-color-white)
        }
      }
    }

    & > .arrow-container {
      button {
        background: none;
        color: var(--ion-color-primary);
        font-size: 20px;
        min-height: 2rem;
        height: 100%;
        width: 3rem;
        margin-left: 0.5rem;
        margin-right: -0.5rem;

        &:focus {
          outline: none;
        }
      }
    }
  }

  .cta-continue {
    margin-left: auto;
  }
}
