.custom-modal.alert-modal {
  box-shadow: 0 11px 15px -7px rgb(0 0 0 / 20%), 0 24px 38px 3px rgb(0 0 0 / 14%), 0 9px 46px 8px rgb(0 0 0 / 12%);
  border-radius: 4px;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  background: var(--background);
  contain: content;
  z-index: 10;
  min-width: 250px;
  width: auto;
  min-height: auto;
  height: auto;
  max-height: 90%;
  background: var(--ion-overlay-background-color, var(--ion-background-color, #fff));
  max-width: 280px;
  font-size: 14px;
  .alert-modal-wrapper {
    padding: 1.8rem 1.5rem 0.5rem;
  }
  .alert-modal-message {
    font-size: 16px;
    color: var(--ion-color-step-550, #737373);
    padding: 0.5rem 0 2rem;
  }
  .alert-modal-button {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    width: 100%;
  }
  .cancel-btn {
    color: var(--ion-color-secondary);
    font-weight: 500;
    background: none;
    display: block;
    text-transform: uppercase;
    padding: 0.75rem 0 0.5rem;
  }
}