@import '../../../ui/theme/breakpoints';

.voucher-contact {

  .voucher-gallery-header {
    height: calc(18rem + env(safe-area-inset-top, 0));
    @include lg {
      height: 100%;
    }

    video {
      max-width: initial;
      max-height: initial;
      width: 100%;
      height: 100%;
      object-fit: cover;
      aspect-ratio: 1.775;
    }
  }

  p {
    text-align: center;
    margin: 0;
  }

  @include lg {
    font-size: 18px;
    h5 {
      font-size: 25px;
    }
  }

  .voucher-login-header {
    padding-top: 2rem;
    padding-bottom: 0.75rem;
    @include lg {
      padding-top: 0.4rem;
      padding-bottom: 0.4rem;
    }
  }

  .login-button-wrapper {
    display: flex;
    flex-wrap: wrap-reverse;
    flex-direction: column;
    gap: 0.25rem;

    @media (min-width: 350px) {
      flex-wrap: initial;
    }

    ion-button {
      width: 100%;
      min-width: auto;
      max-width: 100%;
      height: 3.5rem;
      font-size: 1.3rem;
      border-radius: 0.75rem;
      vertical-align: middle;
      --padding-end: 0.75rem;
      --padding-start: 0.5rem;
      margin: 0;
      @include xl {
        font-size: 1.6rem;
      }
    }
  }

  .voucher-header {
    font-weight: 600;
  }

  .with-sh-btn {
    margin: 0;
    height: 3rem;
  }
}
