@import '../../../ui/theme/breakpoints';

swiper-container {
  max-height: 100%;
  max-width: 100%;

  .swiper-content {
    max-width: none;
    max-height: none;
    width: 100%;
    height: 100%;
    object-fit: cover;

    img, video {
      object-fit: cover;
      max-width: initial;
      max-height: initial;
      width: 100%;
      height: 100%;
    }
  }


  &::part(button-next) {
    background-image: url('../../../ui/theme/images/arrow-right.svg');
    width: 3rem;
    height: 3rem;

  }

  &::part(button-prev) {
    background-image: url('../../../ui/theme/images/arrow-left.svg');
    width: 3rem;
    height: 3rem;
  }

  &::part(bullet-active) {
    background-color: var(--ion-color-primary);
  }
}

:root {
  --swiper-navigation-top-offset: 65%
}