.stripe-payment {
  .save-card-prompt {
    font-size: 14px;
    border-top: 1.5px solid var(--ion-color-white);
    --border-color: var(--ion-color-white);
    --padding-top: 1rem;
    --padding-bottom: 1rem;

    ion-checkbox {
      --checkmark-color: var(--ion-color-primary);
      --background: none;
      --background-checked: none;
      --border-radius: 0.5rem;
      --border-width: 1.5px;
      --checkmark-width: 3px;
    }
  }

  .card-element-container {
    margin: 1rem 0;
  }

  .pay-button {
    margin-top: 2rem;
  }
}