.date-input {
  .form-error {
    margin: 0 auto 0 0.5rem;
  }

  .form-label {
    font-size: 1rem;
    margin: 0;
    padding-left: 0.55rem;
    padding-bottom: 1rem;
  }

  .month-select {
    padding: 0 .6rem;
  }
}