@import '../../../ui/theme/breakpoints';

.voucher-payment-modal {
  ion-footer {
    //noinspection ALL
    .content {
      display: flex;
      flex-direction: row;
      padding: 1.5rem 1rem calc(1.5rem + constant(safe-area-inset-bottom, 0));
      padding: 1.5rem 1rem calc(1.5rem + env(safe-area-inset-bottom, 0));
      align-items: center;
      @include lg {
        max-width: #{$desktop-max-width};
        width: 100%;
        margin: 0 auto;
        background: none;
      }

      ion-button {
        flex: 1;
      }
    }
  }
}