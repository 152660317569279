@font-face {
    font-family: 'Lato';
    font-weight: 400;
    font-style: normal;
    src: url('fonts/Lato-Regular.ttf');
}
@font-face {
    font-family: 'Lato';
    font-weight: 600;
    src: url('fonts/Lato-Bold.ttf');
}
@font-face {
    font-family: 'Lato';
    font-weight: 300;
    src: url('fonts/Lato-Light.ttf');
}

@font-face {
    font-family: 'Montserrat';
    font-weight: normal;
    src: url('fonts/Montserrat-Regular.ttf');
}
@font-face {
    font-family: 'Montserrat';
    font-weight: 700;
    src: url('fonts/Montserrat-Bold.ttf');
}
@font-face {
    font-family: 'Montserrat';
    font-weight: 600;
    src: url('fonts/Montserrat-SemiBold.ttf');
}
@font-face {
    font-family: 'Montserrat';
    font-weight: 500;
    src: url('fonts/Montserrat-Medium.ttf');
}

