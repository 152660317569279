@import "../theme/breakpoints";

//noinspection ALL
.treatment-type-gallery-header {
  @include lg {
    height: 100%;
  }

  .swiper-container {
    height: calc(18rem + constant(safe-area-inset-top, 0));
    height: calc(18rem + env(safe-area-inset-top, 0));
    @include lg {
      height: 100%;
    }

    img, video {
      max-width: initial;
      max-height: initial;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    .default-video {
      object-position: 0 -7rem;
      @include lg {
        object-position: initial;
      }
    }
  }

  .swiper-pagination.swiper-pagination-bullets {
    bottom: 3.5rem;

    .swiper-pagination-bullet {
      border-color: var(--ion-color-white);
      box-shadow: 0px 0px 3px 2px rgb(0 0 0 / .25);

      &.swiper-pagination-bullet-active {
        background: var(--ion-color-white);
      }
    }
  }
}
