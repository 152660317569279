.button-selector {
  .expand-button {
    border: 1px solid var(--ion-color-tertiary);
    color: var(--ion-color-tertiary);
    background: var(--ion-color-tertiary-contrast);
    transition: all 0.25s ease-in-out;
    font-size: 16px;

    &:focus {
      outline: none;
    }

    &.selected {
      background: var(--ion-color-linen);
      border: 1px solid var(--ion-color-linen);

      & > .icon-container {
        div, svg {
          &::after {
            transition: background-color 0.25s ease-in-out;
            background-color: var(--ion-color-white);
          }
        }
      }
    }

    display: flex;
    flex-direction: row;
    width: 100%;
    height: 3rem;
    align-items: center;
    padding: 0.25rem 1rem;

    & > .icon-container {
      margin-left: 1rem;
      margin-right: 0.75rem;
    }

    & > .text-container {
      flex-grow: 1;
      text-align: left;
    }

    & > .arrow-container {
      svg {
        transform: rotate(0);
        transition: transform ease-in-out 0.25s;
      }
    }
  }

  .selectable-hidden {
    opacity: 0;
    position: absolute;
    left: 200%;
    transition: none;
  }

  .selectable-container {
    overflow: hidden;
    transition: max-height linear 0.25s;

    &.select-length {
      transition: max-height linear 0.15s;
      max-height: 0;
    }
  }

  &.expanded {
    .selectable-container {
      overflow-y: auto;
      animation: hide-scroll 0.25s backwards;
      @keyframes hide-scroll {
        from, to {
          overflow: hidden;
        }
      }

      &.select-length {
        max-height: 200px;
      }
    }

    .expand-button > .arrow-container svg {
      transform: rotate(-90deg);
    }
  }

  ion-item {
    cursor: pointer;

    &:first-child {
      margin-top: 0.5rem;
    }
  }

  &.disabled .expand-button {
    border: 1px solid var(--ion-color-medium);
    color: var(--ion-color-medium);
    cursor: default;
  }
}