@use 'sass:math';
@import '../../theme/breakpoints';

$content-border-radius: 0;
.sub-page-layout {
  @include lg {
    background: var(--ion-color-primary);
  }

  ion-content::part(scroll)::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background: var(--ion-color-vanilla);
  }

  ion-content {
    @include lg {
      &::part(background)::after {
        display: none;
      }
      &::part(scroll)::before {
        content: '';
        display: block;
        position: fixed;
        max-width: #{$desktop-max-width};
        width: auto;
        height: auto;
        right: 50%;
        bottom: 0;
        top: 64px;
        left: calc(max(0, (100vw - #{$desktop-max-width}) / 2));
        background: url('../../theme/images/background_mobile.jpg') no-repeat center center;
        background-size: cover;
        z-index: -5;
      }
    }
  }

  @include lg {
    .action-container {
      position: absolute;
      top: -5rem;
      left: calc(1rem - 5vw);
      width: 100%;
      max-width: #{$desktop-max-width};

      @media (min-width: $desktop-max-width) {
        left: -5rem;
      }
    }
  }

  .return-btn {
    &::part(native) {
      padding: 0;
      border: none;
    }

    --background-activated: none;
    background: transparent;
    color: var(--ion-color-tertiary);
    height: 2.5rem;
    width: 2.5rem;
    fill: #000000;
  }

  .before-content {
    text-align: center;
    color: var(--ion-color-dark);
    position: sticky;
    top: 0;
    opacity: 1;
    transition: opacity 0.25s ease-in-out;
    //noinspection ALL
    .no-content {
      width: 100%;
      height: calc(7.5rem + constant(safe-area-inset-top));
      height: calc(7.5rem + env(safe-area-inset-top));
      @include lg {
        height: 100%;
      }
    }

    p {
      margin: 0 0 0.5rem;
    }
  }

  .header-md:after {
    display: none;
  }

  ion-content {
    --background: none;
  }

  //noinspection ALL
  ion-header {
    position: absolute;
    margin-top: constant(safe-area-inset-top);
    margin-top: env(safe-area-inset-top);

    .action-container {
      height: 0;
    }

    @include lg {
      background: var(--ion-color-white);
      box-shadow: 0 0 8px var(--ion-color-dark);
      display: flex;
      flex-direction: column;
      position: sticky;
      .action-container {
        position: absolute;
        height: initial;
        top: 4rem;
        left: 50%;
        width: 100%;
        max-width: #{$desktop-max-width};
        transform: translateX(-50%);
      }
    }

    .return-btn {
      position: relative;
      left: 0.75rem;
      top: 2rem;
      color: var(--ion-color-tertiary);
    }

    .header {
      display: none;
      width: 100%;
      max-width: #{$desktop-max-width};
      align-self: center;
      @include lg {
        display: flex;
      }
    }
  }

  .swiper-pagination.swiper-pagination-bullets {
    position: relative;
    @include lg {
      bottom: initial;
      top: calc(100vh - 7rem);
      position: absolute;
    }
  }

  .scroll-container {
    display: flex;
    flex-direction: column;
    min-height: 100%;
    overflow: hidden;

    @include lg {
      // makes sure that even with scrollbar the container is centered within the viewport
      margin: 0 auto;
      width: 100%;
      max-width: #{$desktop-max-width};
      align-self: center;
      justify-self: center;
      //min-height: calc(100% - 76px);
      flex-direction: row;
      .before-content {
        flex-basis: 50%;
        top: 0;
        overflow: hidden;
        height: calc(100vh - 76px);
        position: relative;
        align-self: flex-start;

        & > * {
          position: fixed;
          left: max(var(--zero-px), calc(50vw - (#{math.div($desktop-max-width, 2)})));
          top: 64px;
          bottom: 0;
          right: 50vw;
        }

        .slide-content {
          position: sticky;
          left: 0;
          top: 6rem;
          flex-basis: 50%;
          flex-direction: column;
          justify-content: space-between;
          text-align: left;
          padding: 0 3rem 2rem 6rem;

          .slide-title {
            font-size: 32px;
            font-weight: 600;
            margin-bottom: 2rem;
          }

          .slide-text {
            font-size: 24px;
          }

          .slide-button {
            font-size: 18px;
            height: 3rem;
            min-width: 10rem;
            margin-top: 2rem;
          }
        }
      }
      .content-container {
        flex: 1;
        margin: 6rem calc((100% - 100vw)) 0 5vw;
        top: 0;
        display: block;
      }
    }

    @media (min-width: $desktop-max-width) {
      .content-container {
        margin: 6rem 6rem 0 6rem;
        max-width: initial;
        min-width: initial;
      }
    }

    &::after {
      content: '';
      display: block;
      flex-grow: 1;
      position: relative;
      background: var(--ion-color-white);
      @include lg {
        display: none;
      }
    }

    &.scrolled {
      .before-content {
        z-index: -5;
        opacity: 0.25;
      }
    }
  }

  .content-container {
    position: relative;
    z-index: 10;
    transform: translate3d(0, 0, 0);
    flex-shrink: 0;
    padding: 0.5rem 0 0;
    min-height: $content-border-radius;
    display: flex;
    flex-direction: column;

    & > ion-grid {
      width: 100%;
    }

    @include lg {
      max-width: 40vw;
      min-width: 40vw;
    }

    &::before {
      transform: translate3d(0, 0, 0);
      content: '';
      position: absolute;
      top: 0;
      bottom: -1000px;
      left: 0;
      right: 0;
      z-index: -1;
      background: var(--ion-color-white);
    }
  }

  ion-footer {
    box-shadow: none;
  }

  @include lg {
    & > ion-footer {
      background: white;
    }
  }

  h1 {
    margin-top: 0;
  }

  .before-content {

    .slide-title {
      margin-bottom: 0.25rem;
    }

    //noinspection ALL
    .slide-content {
      font-size: 14px;
      position: sticky;
      left: 0;
      top: 6rem;
      flex-basis: 50%;
      flex-direction: column;
      justify-content: space-between;
      text-align: left;
      padding: 2rem 3rem 2rem 6rem;
      padding-top: calc(constant(safe-area-inset-top) + 2rem);
      padding-top: calc(env(safe-area-inset-top) + 2rem);
    }
  }

  .sub-page-header {
    @include lg {
      position: sticky;
      top: 0;
      z-index: 9999;
    }
  }
}
