@import '../../../ui/theme/breakpoints';

@mixin routeType {
  padding: 0 2rem;
  overflow: hidden;
  margin-bottom: 0.95rem;
}

@mixin tilePhoto {
  object-fit: cover;
  display: block;
  width: 100%;
  max-width: 100%;
  min-height: 7rem;
  aspect-ratio: 3/1;

  @include lg {
    max-height: 12rem;
  }
}

.home {
  .before-content {
    padding: 0;
  }

  @media (max-width: $screen-lg-min - 1px) {
    .content-container {
      min-height: 100%;
      justify-content: center;
    }
    .scroll-container {
      overflow: auto;
      height: 100%;
    }
  }

  ion-content::part(background)::after, .scroll-container::after, .content-container::before {
    background: none;
  }

  ion-grid {
    margin-bottom: 0.5rem;
  }

  ion-col {
    padding-bottom: 0.25rem;

    &:first-child {
      padding-top: 0.25rem;
    }
  }
}


.tilePhoto {
  @include tilePhoto
}

.locationTilePhoto {
  @include tilePhoto;
  object-position: 50% 65%;
}

.routeType {
  @include routeType;
}

.massageRoute {
  @include routeType;

  & .tilePhoto {
    object-position: 50% 10%;
  }
}

.routeDescription {
  text-decoration: none;
  color: #3C3C3B;
  font-size: 1.125rem;
  text-transform: uppercase;
  font-family: 'Montserrat', 'SF Pro', sans-serif;
  font-weight: 600;
  margin-top: 0.5rem;
  text-align: center;
}

.stretchHome {
  filter: opacity(0.3);
}

.badgeWrapper {
  position: relative;
  display: block;
}

.tileBadge {
  position: absolute;
  z-index: 999;
  left: 50%;
  top: 50%;
  margin: 0;
  text-transform: uppercase;
  transform: translate(-50%, -50%);
  color: #000000;
  font-size: 1.125rem;
  font-family: 'Montserrat', 'SF Pro', sans-serif;
  font-weight: 600;
  text-wrap: nowrap;
  padding: 1rem;
  background: var(--ion-color-white);
}

.promoBanner {
  padding: 0 2rem;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  margin-bottom: 0.75rem;
  flex: 0 1 auto;
  min-height: 0;
  flex-shrink: 0;

  &__content {
    line-height: 1.45;
    border: 1px solid var(--ion-color-secondary);
    padding: 1.25rem 0.5rem;
    color: var(--ion-color-secondary);
    text-align: center;
    font-size: 0.925rem;
    font-weight: 700;
  }

  &__button {
    background: none;
    color: var(--ion-color-secondary);
    font-size: 0.925rem;
    font-weight: 700;
  }

  &__icon {
    margin-left: 0.45rem;
    font-size: 1rem;
    align-items: center;
  }
}