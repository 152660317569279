@import '../../../ui/theme/breakpoints';

.booking-request {
  margin-top: 2rem;
}

.treatment-selector {
  @include lg {
    font-size: 18px;
    .scroll-container.remove-left-offset {
      .content-container {
        right: -1.35rem;
      }
    }
    h5 {
      font-size: 25px;
    }
  }

  ion-spinner {
    display: block;
    margin: 2rem auto;
  }

  .treatment-header {
    font-weight: 600;
  }

  .show-more {
    background: none;
    color: var(--ion-color-primary);
    cursor: pointer;
    padding: 0;
    display: inline-block;

    &:focus {
      outline: none;
    }
  }

  p.type-description {
    margin: 0;
  }

  .treatment-results-container {
    width: 100%;
  }

  //noinspection All
  .treatment-summary {
    display: flex;
    flex-direction: row;
    padding: 1rem 2rem calc(1rem + var(safe-area-inset-bottom, 0));
    padding: 1rem 2rem calc(1rem + env(safe-area-inset-bottom, 0));
    align-items: center;

    & > .date {
      flex: 1;
      font-size: 18px;
    }

    & > .next-container ion-button {
      min-width: 6rem;
    }
  }
}

:root {
  --select-border: var(--ion-color-primary);
  --select-focus: var(--ion-color-primary);
}

.treatment-header-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}

.booking-info {
  margin: 0;
}

.booking-request-address {
  ion-col {
    padding: var(--ion-grid-column-padding-xl, var(--ion-grid-column-padding, 5px)) 0;
  }
}

.results-heading {
  line-height: 1;
  padding-top: 0;
  font-weight: 600;
  margin-top: 0.375rem;

  .fulfilled {
    font-weight: 600;
    line-height: 1;
  }
}

.ask-to-scroll {
  margin: 0 18px;
  position: absolute;
  left: 0;
  z-index: 999;
  width: calc(100% - 35px);
  height: 2.25rem;
  justify-content: center;
  align-items: center;
  box-shadow: 0 0 10px rgba(var(--ion-color-medium-rgb), 0.3);
  background-color: var(--ion-color-tertiary);
  display: none;
  @include lg {
    left: calc(55% + 34px);
    position: absolute;
    margin: 0;
    width: calc(40vw - 68px);
  }

  &__text {
    margin: 0;
    line-height: 1;
  }

  &.show {
    display: flex;
  }

  &.hidden {
    display: flex;
    visibility: hidden;
    opacity: 0;
    transition: visibility 0s 0.5s, opacity 0.5s linear;
  }
}