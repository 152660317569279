
.PhoneInput {
  padding: 0.75rem 1rem;
  border: 1px solid var(--ion-color-medium);
  --PhoneInputCountrySelectArrow-color: var(--ion-color-dark);
  --PhoneInputCountrySelectArrow-color--focus: var(--ion-color-dark);
}

.PhoneInputInput {
  border: none;
  padding: 0 0.5rem;
  margin-left: 0.75rem;
  background: none;

  &:focus {
    outline: none;
  }
}

.PhoneInputCountry::before {
  content: "";
  display: block;
  width: 1px;
  background: var(--ion-color-medium);
  right: -0.75rem;
  top: -0.8125rem;
  bottom: -0.8125rem;
  position: absolute;
}

.PhonePrefix {
  margin-left: .5rem;
}