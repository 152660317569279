.referral {
  &__code-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__code {
    border: 1px solid var(--ion-color-tertiary);
    padding: 1rem;
    color: var(--ion-color-secondary);
    font-weight: 600;
    flex: 1 auto;
    margin-right: 1.25rem;
    margin-bottom: 1rem;
  }

  button.share {
    width: 2.5rem;
    height: 2.5rem;
    text-align: center;
    background: var(--ion-color-white);
    font-size: 20px;
    margin-right: 0.25rem;

    &:focus {
      outline: none;
    }

    svg {
      margin-left: 0.125em
    }
  }

  &__title {
    margin-top: 2rem;
  }

  &__title, &__balance, &__your-code, &__how {
    font-size: 1rem;
    font-weight: 600;
    margin-bottom: 0;
  }

  &__price {
    font-weight: 600;
    color: var(--ion-color-secondary);
  }
}