@import '../../../ui/theme/breakpoints';

.onboarding-slider {
  height: 100%;
  //noinspection ALL
  &::part(pagination) {
    bottom: calc(0.5rem + constant(safe-area-inset-bottom, 0));
    bottom: calc(0.5rem + env(safe-area-inset-bottom, 0));
  }

  //noinspection ALL
  .slide {
    padding: 2rem;
    justify-content: center;
    background: no-repeat center center;
    display: flex;
    background-size: cover;
    color: var(--ion-color-primary);

    .slide-content {
      height: 100%;
      max-width: 19rem;
      text-align: left;
      flex-direction: column;
      justify-content: center;
      display: flex;
    }

    &.step-1 {
      background-image: url('images/step1.jpg');
    }

    &.step-2 {
      background-image: url('images/step2.jpg');
    }

    &.step-3 {
      background-image: url('images/step3.jpg');
    }

    .content {
      margin: 5rem 0 0;
      width: 100%;
      border: 1px solid var(--ion-color-primary);
      padding: 0.75rem;
      font-weight: 600;
      text-align: center;
      font-size: 1rem;
    }

    .logo {
      fill: var(--ion-color-primary);
      padding: 0;
      margin-top: calc(50vh - 13rem);
      font-size: 1rem;
      height: 16rem;
      width: 100%;
    }
  }

  .slide-btn {
    margin: auto 0 2.5rem;
  }
}
