@import '../../../ui/theme/breakpoints';

.booking-summary {
  h4 {
    margin-top: 0;
    margin-bottom: .8rem;
    font-weight: 600;
    @include lg {
      margin-bottom: 1.5rem;
    }
  }

  h4.booking-summary-form_header {
    margin-top: 0.25rem;
  }

  h5 {
    margin-top: 0;
  }

  .form-input {
    margin-bottom: 1rem;
  }

  .no-padding-top {
    padding-top: 0;
  }

  .form-input textarea {
    --placeholder-color: var(--ion-color-tertiary);
    --placeholder-opacity: 1;
    font-size: 16px;
    min-height: 5.5rem;
  }

  .selected {
    background: var(--ion-color-primary);
    color: var(--ion-color-primary-contrast);

    & > .icon-container {
      div, svg {
        &::after {
          transition: background-color 0.25s ease-in-out;
          background-color: var(--ion-color-white);
        }
      }
    }
  }

  .button-selector {
    margin-bottom: 1rem;

    .icon-container {
      margin-right: 0.75rem;
    }

    &.massage-table-selector, &.place-selector {
      .icon-container {
        margin-left: 0;
      }
    }

    &.place-selector {
      display: none;
    }
  }

  .booking-details-form {
    margin-top: 1rem;

    .hidden {
      display: none;
    }
  }
}
