.social-buttons-wrapper {
  display: flex;
  margin: 0.5rem 0;
}

.social-button {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 2.75rem;
  margin-bottom: 0.75rem;
  vertical-align: middle;
  background: white;
  color: var(--ion-color-black);
  position: relative;
  border: 1px solid var(--ion-color-black);
  padding: 0;
  text-wrap: nowrap;
  min-width: 15rem;

  &:hover {
    background: var(--ion-color-white);
  }

  &:focus {
    outline: 1px;
  }

  &.facebook {
    .icon {
      width: 1.25rem;
      height: 1.25rem;
      position: absolute;
      left: 0.85rem;
      top: 50%;
      transform: translate(0, -50%);
    }
  }

  &.apple {
    .icon {
      position: absolute;
      left: 0;
      top: -2px;
      height: 3rem;
      width: 3rem;
    }
  }
}