@import '../../../../ui/theme/breakpoints';

.noExpertsText {
  font-weight: bold;
}

.bookingRequest {
  margin-top: 2rem;
}

.bookingRequestCol {
  padding: 0;
}

.dividerLine {
  display: flex;
  width: 100%;
  height: 1px;
  background: var(--ion-color-medium);
  margin-top: 0.5rem;
  margin-bottom: 2rem;
}
