@import '../../../ui/theme/breakpoints';

.treatment-details-before-content {
  min-height: 18rem;
  background: var(--ion-color-dark);
  position: relative;
  //noinspection ALL
  .swiper-container {
    height: calc(18rem + constant(safe-area-inset-top));
    height: calc(18rem + env(safe-area-inset-top));
    @include lg {
      height: 100%;
      z-index: 5;
    }
  }

  //noinspection ALL
  &.modal .swiper-container {
    height: calc(18rem + constant(safe-area-inset-top));
    height: calc(18rem + env(safe-area-inset-top));
  }

  .swiper-content {
    height: 100%;

    img, video {
      object-fit: cover;
      max-width: initial;
      max-height: initial;
      width: 100%;
      height: 100%;
      pointer-events: none;
    }
  }

  @include lg {
    z-index: 100;
  }
}

.treatment-details {
  &.sub-page-layout {
    ion-content::before {
      display: none;
    }

    .content-container {
      top: 0;
      margin-bottom: 0;

      &::before {
        background: var(--ion-color-linen);
        @include lg {
          left: -5vw;
          right: -5vw;
        }
      }
    }
  }

  .info-card {
    background: var(--ion-color-white);
    padding: 1rem 1rem 0;
    margin-top: -6rem;
    position: relative;
    text-align: center;

    button.share {
      position: absolute;
      right: 0.75rem;
      top: 0.5rem;
      width: 2rem;
      height: 2rem;
      text-align: center;
      color: var(--ion-color-primary);
      background: transparent;

      &:focus {
        outline: none;
      }
    }

    h4 {
      font-size: 20px;
      margin: 1.5rem 0 0.75rem;
    }

    .slogan {
      margin: 0 0 0.5rem;
    }

    .languages {
      & > svg {
        font-size: 20px;
        margin-right: 1rem;
        transform: translateY(2px);
      }

      margin: 1.5rem 0 1.25rem;
    }

    .details-container {
      display: flex;
      flex-direction: row;
      margin: 0 -1rem;
      border-top: 1.5px solid var(--ion-color-white);

      & > .cell {
        flex-basis: 50%;
        margin: 1rem 0;

        &:nth-child(2n) {
          border-left: 1.5px solid var(--ion-color-white);
        }

        .label {
          color: var(--ion-color-secondary);
          font-size: 14px;
        }

        .value {
          font-size: 18px;
          font-weight: 600;
        }

        .phone {
          color: var(--ion-color-tertiary);
        }
      }
    }
  }

  ion-list {
    padding: 0.5rem 0;

    ion-item {
      color: var(--ion-color-primary);
      --background: none;
    }
  }

  .description {
    padding-bottom: 0;
  }

  .services {
    padding-top: 0;
  }

  .select-button {
    margin-top: 2rem;
    padding-bottom: 5rem;
    @include md {
      padding-bottom: initial;
    }
  }

  &.info-single-row {
    .info-card {
      .details-container {
        flex-direction: column;
        padding: 0.75rem 0;
        gap: 0.75rem;
        margin: 0;

        & > .cell {
          margin: 0;
          padding-top: 1rem;
          text-align: left;
          border-top: 1px solid var(--ion-color-medium-tint);
          font-size: 1rem;
          line-height: 1.35;

          .label {
            color: var(--ion-color-secondary);
            font-weight: 600;
          }

          .value {
            font-weight: 600;
          }
        }
      }
    }
  }
}


.custom-modal-overlay {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.32);
}
