@import '../../../ui/theme/breakpoints';

.my-bookings {
  .bookings-col {
    position: relative;
  }

  h3 {
    font-size: 24px;
    margin-bottom: 1rem;
    margin-top: 0;
    max-width: calc(100% - 5rem);
  }

  .booking-card:not(:last-child) {
    margin-bottom: 1rem;
  }

  .toggle {
    position: absolute;
    text-align: right;
    right: 0.5rem;
    top: .9rem;
    background: none;
    outline: none;
    font-size: 16px;
    color: var(--ion-color-tertiary);
    line-height: 1.2;

    &:focus {
      outline: none;
    }
  }

  .homescreen-btn {
    position: fixed;
    left: 50%;
    bottom: 0;
    transform: translateX(-50%);
    padding-bottom: 1rem;
    z-index: 99;
    @include lg {
      display: none;
    }
  }
}