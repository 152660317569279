.booking-details {
  .content-container {
    padding-top: 2rem;
  }
  ion-spinner {
    margin: 1rem auto;
    //transform: scale(3);
    width: 4rem;
    height: 4rem;
    text-align: center;
    --color: var(--ion-color-primary);
  }
  .spinner-row {
    text-align: center;
  }
  .not-paid {
    margin-top: 2rem;
    text-align: center;
    font-size: 18px;
  }
}