@import '../../ui/theme/breakpoints';

body {
  background-color: var(--ion-color-white);
}

h1, h2, h3, h4, h5, h6, ion-header {
  font-family: "Montserrat", sans-serif;
}

ion-button {
  text-transform: none;
  font-weight: 600;
  --border-radius: none;
}

.input-highlight {
  // ionic is adding bottom border to every input
  background: transparent !important;
}

input, textarea {
  caret-color: var(--ion-color-tertiary) !important;
}

.form-input {
  --padding-start: 1rem;
  --padding-end: 1rem;
  --padding-bottom: 0.75rem;
  --padding-top: 0.75rem;
  //border-radius: 1rem;
  overflow: hidden;
  border-width: 2px;
}

.form-label {
  font-size: 14px;
  margin-left: 0.75rem;
  margin-bottom: 0.25rem;
}

ion-input.form-input, ion-textarea.form-input, ion-select.form-input {
  border: 1px solid var(--ion-color-tertiary);
}

.form-error {
  font-size: 12px;
  margin-top: 0.5rem;
  color: var(--ion-color-danger);
  margin-left: 0.75rem;
}

.invisible-auto-submit {
  position: absolute;
  left: -9999px;
  visibility: hidden;
}

//noinspection ALL
.booking-modal {
  .close-button {
    position: sticky;
    top: 0;
    left: 0;
    background: rgba(255, 255, 255, 0.5);
    border-radius: 50%;
    z-index: 5;
    width: 4rem;
    height: 4rem;
    font-size: 24px;
    color: var(--ion-color-dark);

    &:focus {
      outline: none
    }
  }

  svg.header-icon {
    font-size: 96px;
    display: block;
    color: var(--ion-color-secondary);
    margin: env(safe-area-inset-top, 0) auto 1rem;
    @include lg {
      margin: calc(-1rem - env(safe-area-inset-top, 0)) auto 1rem;
    }
  }

  h3 {
    text-align: center;
    font-weight: 600;
    margin: 1.5rem 0 0.5rem;
  }

  p.message {
    text-align: center;
    margin-top: 0;
  }

  .code {
    margin: 1.5rem 0;
    text-align: center;
    font-weight: 600;
  }
}

h4 {
  font-size: 18px;
  font-weight: 600;
}

.ion-link {
  display: inline-block;
  color: var(--ion-color-secondary);
}

// TODO refactor - create a new component for this, shared between booking and voucher payment
.provider-logo {
  margin: .5rem;
  display: inline-block;
  width: 4rem;
  height: 2rem;

  &-stripe_web, &-card_payment {
    background: url(../../ui/theme/images/combined_cards.svg) no-repeat center center/contain;
  }

  &-sofort {
    background: url(../../ui/theme/images/klarna_payment.svg) no-repeat center center/contain;
  }

  &-apple_pay {
    background: url(../../ui/theme/images/apple-pay.svg) no-repeat center center/contain;
  }

  &-google_pay {
    background: url(../../ui/theme/images/google-pay.svg) no-repeat center center/contain;
    margin: -.5rem -.3rem;
    height: 3.5rem;
    width: 5.5rem;
  }

  &-paypal {
    background: url(../../ui/theme/images/paypal.png) no-repeat center center/contain;
  }
}

.cancel-payment {
  margin-top: 1rem;
}

.address-header {
  margin: 0;
}

[popover].popover-viewport {
  display: initial;
  position: initial;
  margin: initial;
  border: initial;
  background: initial;
  padding: initial;
  width: initial;
  height: initial;
  overflow: initial;
  inset: initial;
  color: initial;
}

ion-list {
  box-shadow: none;
}

ion-textarea {
  border: 1px solid var(--ion-color-tertiary);
  color: var(--ion-color-tertiary);
  --placeholder-opacity: 0.9;
}

.header-md, .footer-md {
  box-shadow: none;
}

// ionic bug - randomly scrolls up when focusing input
ion-content {
  --keyboard-offset: 0 !important;
}

//ionic be default is using primary color for buttons
.alert-wrapper {
  button.alert-button.alert-button-confirm,
  button.alert-button.alert-button-cancel,
  button.alert-button {
    color: var(--ion-color-tertiary);
  }
}

.gallery-header {
  .gallery-img {
    height: 100%;
    object-fit: cover;
    object-position: top;
    width: 100%;
  }
}