@import '../../../../ui/theme/breakpoints';

.resultCard {
  display: flex;
  flex-direction: row;
  padding: 1rem;
  background: var(--ion-color-linen);
  height: 100%;

  //noinspection ALL
  ion-avatar {
    min-width: 68px;
    width: 68px;
    height: 68px;
    @include sm {
      min-width: 72px;
      width: 72px;
      height: 72px;
    }

    &:hover {
      cursor: pointer;
    }
  }

  .expert-info-container {
    padding: 0 0.75rem;
    align-self: center;

    @include sm {
      display: flex;
      flex-direction: column;
      gap: 0.2rem
    }

    @include sm-content {
      flex: initial;
      display: flex;
      flex-direction: column;
    }
  }
}

.resultCardWithAvailabilities {

  background: var(--ion-color-linen);
  height: 100%;

  .cardWrapper {
    padding: 1rem;
  }

  .expertPhoto {
    aspect-ratio: 16/9;
    object-position: 100% 20%;
    width: 100%;
    object-fit: cover;
  }
}

.treatmentTypes {
  margin-top: 1.15rem;

  .treatmentItem {
    margin-top: 0.55rem;
    padding-left: 0.75rem;
    --ion-background-color: transparent;
    background: none;
    display: grid;
    grid-template-areas:
            "label button"
            "details button";

  }

  .typeSelected {
    border-radius: 16px;

    ion-label {
      color: var(--ion-color-primary-contrast);
    }
  }

  .selectButton {
    margin: 0;
    grid-area: button;
    justify-self: end;
    color: var(--ion-color-tertiary);
    --background-activated-opacity: 0;


    ion-label {
      margin-right: 0.75rem;
    }

    &:hover {
      cursor: pointer;
    }
  }

  .typeLabel {
    max-width: fit-content;
    margin-bottom: 0.25rem;
    grid-area: label;
    color: var(--ion-color-tertiary);

    &:hover {
      cursor: pointer;
    }
  }

  .details {
    grid-area: details;
    background: none;
    color: var(--ion-color-secondary);
    justify-self: start;
    font-size: 0.825rem;
  }
}

.expandableSelect {
  display: flex;
  flex-direction: column;
  background: var(--ion-color-linen);

  ion-item {
    cursor: pointer;
  }

  .selectableContainer {
    overflow: hidden;
    transition: max-height linear 0.25s;
  }

  &.expanded {
    .selectableContainer {
      overflow-y: auto;
      animation: hide-scroll 0.25s backwards;
      @keyframes hide-scroll {
        from, to {
          overflow: hidden;
        }
      }
    }
  }

  .selectableHidden {
    opacity: 0;
    position: absolute;
    left: 200%;
    transition: none;
  }
}


//noinspection All
ion-button.select-wrapper::part(native) {
  padding-right: 4px;
}

.showMoreBtn {
  color: var(--ion-color-tertiary);
  padding-bottom: 0;
  margin: 0;

  &::part(native) {
    padding-bottom: 0;
  }

  &.hidden {
    display: none;
  }

  svg {
    display: block;
    margin-left: 0.45rem;
  }
}

.no-wrap {
  white-space: nowrap;
}
