@import "../../theme/breakpoints";
//noinspection All
.booking-footer-slots {

  background: var(--ion-color-white);
  display: flex;
  flex-direction: row;
  //padding: 1rem 2rem constant(safe-area-inset-bottom, 1rem);
  padding: 1rem 1.125rem max(1rem, env(safe-area-inset-bottom));
  align-items: center;
  @include lg {
    max-width: #{$desktop-max-width};
    width: 100%;
    margin: 0 auto;
    background: none;
  }

  & > .date, & > .text {

    flex: 1;
    font-size: 18px;
    //color: #000;
    color: var(--ion-color-tertiary);

    @include lg {
      margin-left: calc(50% + 1rem);
    }
  }

  & > .next-container {
    flex: initial;
    overflow: hidden;
    ion-button {
      min-width: 6rem;
      width: 100%;
      max-width: fit-content;
      margin-left: auto;
      @include lg {
        min-width: 8rem;
      }
    }
  }
}

.availabilities-container {
  &::-webkit-scrollbar {
    @include max-lg {
      display: none;
    }
  }

  &.spa {
    margin-bottom: 1.25rem;
  }

  flex: 1;
  overflow-y: auto;
  display: flex;
  padding-bottom: 0.75rem;

  button {
    background: transparent;
    border: 1px solid var(--ion-color-tertiary);
    color: var(--ion-color-tertiary);
    font-size: 18px;
    padding: 0.5rem 1rem;
    margin-right: 0.5rem;
    transition: all ease-in-out 0.25s;



    &:focus {
      outline: none;
    }

    &.selected {
      background: var(--ion-color-linen);
      border-color: var(--ion-color-linen);
      color: var(--ion-color-tertiary)
    }
  }
}
