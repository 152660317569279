@import '../../../ui/theme/breakpoints';

.booking-contact {
  p {
    text-align: center;
    margin: 0;
  }

  ion-col {
    padding-top: .4rem;
    padding-bottom: .4rem;
    @include lg {
      padding-top: .5rem;
      padding-bottom: .5rem;
    }
  }

  .booking-header {
    ion-col {
      padding-top: 0;
      @include lg {
        padding-top: .5rem;
      }
    }

    h4 {
      margin-top: 0;
      margin-bottom: 0;
    }
  }

  .login-buttons {
    ion-col {
      padding-top: .1rem;
      padding-bottom: .1rem;
      @include lg {
        padding-top: .5rem;
        padding-bottom: .5rem;
      }
    }
  }

  .booking-login-header {
    padding-top: 2rem;
    padding-bottom: 0.75rem;
    @include lg {
      padding-top: 0.4rem;
      padding-bottom: 0.4rem;
    }
  }

  .login-button-wrapper {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap-reverse;
    gap: 0.25rem;

    @media (min-width: 350px) {
      flex-wrap: initial;
    }

    ion-button {
      width: 100%;
      min-width: auto;
      max-width: 100%;
      height: 3.5rem;
      font-size: 1.3rem;
      border-radius: 0.75rem;
      vertical-align: middle;
      --padding-end: 0.75rem;
      --padding-start: 0.75rem;
      margin: 0;
      @include xl {
        font-size: 1.6rem;
      }
    }

    @include lg {
      padding-top: .6rem;
    }
  }

  --ion-grid-column-padding: .5rem;

  .with-sh-btn {
    margin: 0;
    height: 3rem;
  }
}
