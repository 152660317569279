@import '../../../../ui/theme/breakpoints';

$available-color: rgba(var(--ion-color-vanilla-rgb), 0.6);

@mixin svg-icon($icon, $width: 32px, $height: 32px) {
  width: $width;
  height: $height;
  position: relative;
  &::after {
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
    content: '';
    -webkit-mask: $icon no-repeat 50% 50%;
    mask: $icon no-repeat 50% 50%;
    background-color: var(--ion-color-primary);
    -webkit-mask-size: cover;
    mask-size: cover;
    width: $width;
    height: $height;
    @content;
  }
}

.date-modal {
  ion-header {
    background: var(--ion-color-vanilla);
  }

  ion-footer {
    background: none;
  }

  //noinspection ALL
  ion-header {
    padding-top: constant(safe-area-inset-top, 0);
    padding-top: env(safe-area-inset-top, 0);
  }

  //noinspection ALL
  ion-footer {
    text-align: center;
    padding: 1rem 1rem calc(1rem + constant(safe-area-inset-bottom, 0));
    padding: 1rem 1rem calc(1rem + env(safe-area-inset-bottom, 0));

  }

  .header-container {
    display: flex;
    flex-direction: row;
    padding: 0.75rem 0;
    position: relative;
    min-height: 2.5rem;

    & > button {
      position: absolute;
      left: 1rem;
      top: 0;
      bottom: 0;
      width: 4rem;
      font-size: 18px;
      cursor: pointer;
      background: url(./images/left-arrow.svg) no-repeat left center;
      background-size: 1.5rem;

      &:focus {
        outline: none;
      }
    }

    & > .title {
      text-align: center;
      flex: 1;
      font-size: 20px;
      color: black;
    }
  }

  .react-calendar {

    .react-calendar__navigation {
      display: flex;
      align-items: center;
    }

    .react-calendar__navigation__label {
      margin-top: 0.25rem;
    }

    .react-calendar__month-view__days {
      // Override react-calendar display flex styling. The library uses the
      // `style` attribute, so we need to use `!important` to override it.
      display: grid !important;
      gap: 2px;
      grid-template-columns: repeat(7, 1fr);
    }

    .react-calendar__navigation button {
      font-size: 18px;
      background: none;
      padding: 0.25rem 0;
      color: var(--ion-color-dark);

      &:focus {
        outline: none;
      }

      &.react-calendar__navigation__arrow {
        font-size: 36px;
        color: var(--ion-color-dark);
      }
    }

    .react-calendar__viewContainer {
      margin-top: 0.5rem;

      .react-calendar__month-view__weekdays {
        text-align: center;
        padding: 0.75rem 0;
        border-bottom: 1px solid $available-color;

        abbr[title] {
          text-decoration: none;
          color: var(--ion-color-tertiary);
        }
      }

      .react-calendar__tile {
        flex-basis: auto !important;
        max-width: initial !important;
        font-size: 16px;
        height: 3rem;
        background: $available-color;
        transition: 0.25s ease-in-out all;
        margin: 0;
        padding: 0;
        color: #000000;

        &:focus {
          outline: none;
        }

        &.react-calendar__tile--active {
          color: var(--ion-color-black);
          position: relative;
          font-weight: 600;
          background: var(--ion-color-linen);
        }

        &.react-calendar__tile--now {
          border: 1px solid var(--ion-color-linen);;
        }

        &.react-calendar__month-view__days__day--neighboringMonth {
          visibility: hidden;
        }
      }

      .react-calendar__tile[disabled] {
        color: var(--ion-color-medium);
        background: none;
        border: none;
      }
    }
  }

  h4 {
    font-size: 16px;
    margin: 0.5rem 0 1rem;
  }

  .calendar-info {
    margin: 0.6rem 0 0.25rem;

    @include lg {
      margin: -0.3rem 0 0.25rem;
    }
  }

  .time-period-selector {
    display: flex;
    margin: 0 -0.25rem;
    flex-direction: row;

    button.time-period {
      flex: 1;
      margin: 0 0.25rem;
      background: var(--ion-color-primary);
      border: 1px solid var(--ion-color-primary);
      color: var(--ion-color-primary-contrast);
      padding: 1rem;
      @media screen and (max-width: 411px) {
        padding: 1rem 0.2rem;
      }
      @media screen and (max-width: 334px) {
        padding: 1rem 0.45rem;
      }
      @media screen and (max-width: 310px) {
        padding: 1rem 0.25rem;
      }
      display: flex;
      flex-direction: column;
      align-items: center;
      transition: 0.25s ease-in-out all;

      &:focus {
        outline: none;
      }

      .name {
        margin-bottom: 0.25rem;
        font-size: 16px;
      }

      .description {
        margin-bottom: 0.75rem;
      }

      .price {
        font-size: 14px;
        font-weight: 600;
        margin-bottom: 0.75rem;
      }

      & > .icon {
        content: '';
        display: block;
        width: 1.5rem;
        height: 1.5rem;
        background: no-repeat center center;
        background-size: 100%;
        margin-bottom: 0.5rem;

        &::after {
          content: '';
          display: block;
          position: relative;
          width: 1.5rem;
          height: 1.5rem;
          mask-repeat: no-repeat;
          mask-position: 50% 50%;
          transition: background-color ease-in-out 0.25s;
          background-color: var(--ion-color-tertiary);
          -webkit-mask-size: cover;
          mask-size: cover;
        }
      }

      &.morning > .icon::after {
        mask-image: url(./images/sunrise.svg);
      }

      &.afternoon > .icon::after {
        mask-image: url(./images/sun.svg);
      }

      &.evening > .icon::after {
        mask-image: url(./images/moon.svg);
      }

      &.selected {
        background: var(--ion-color-linen);
        border-color: var(--ion-color-linen);
      }

      &.disabled {
        color: var(--ion-color-medium);
        border-color: var(--ion-color-medium);
        border-width: 1px;
        background: none;

        & > .icon::after {
          background-color: var(--ion-color-medium);
        }
      }
    }
  }

  .calendar-wrapper {
    padding-top: 0;
    padding-bottom: 0;
  }
}
