.account-details {
  .hook-data-error {
    padding: 1rem;
    h3 {
      margin-top: 0;
    }
  }
  .profileBtn {
    margin-bottom: 0.5rem;
  }
}
