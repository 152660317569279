@use 'sass:math';
@import '../../../ui/theme/breakpoints';

$content-border-radius: 2rem;

.sub-page-wrapper-for-modal {
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  position: absolute;
  flex-direction: column;
  justify-content: space-between;
  contain: layout size style;
  overflow: hidden;

  ion-content::part(scroll)::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background: var(--ion-color-vanilla);
  }

  .before-content {
    text-align: center;
    color: var(--ion-color-dark);
    position: sticky;
    top: 0;
    opacity: 1;
    transition: opacity 0.25s ease-in-out;

    p {
      margin: 0 0 0.5rem;
    }
  }

  //noinspection ALL
  ion-content {
    --background: none;
  }

  //noinspection ALL
  .swiper-pagination.swiper-pagination-bullets {
    position: relative;
  }

  .scroll-container {
    display: flex;
    flex-direction: column;
    min-height: 100%;
    overflow: hidden;

    &::after {
      content: '';
      display: block;
      flex-grow: 1;
      position: relative;
    }

    &.scrolled {
      .before-content {
        z-index: -5;
        opacity: 0.25;
      }
    }
  }

  .content-container {
    top: 0;
    margin-bottom: 0;
    position: relative;
    z-index: 10;
    transform: translate3d(0, 0, 0);
    flex-shrink: 0;
    padding: 0.5rem 0 0;
    min-height: $content-border-radius;
    display: flex;
    flex-direction: column;

    & > ion-grid {
      width: 100%;
    }

    &::before {
      transform: translate3d(0, 0, 0);
      content: '';
      position: absolute;
      top: 0;
      bottom: -1000px;
      left: 0;
      right: 0;
      background: var(--ion-color-linen);
      z-index: -5;
    }
  }

  h4 {
    font-size: 20px;
    margin: 0.5rem 0 0.75rem;
  }
}

.custom-modal {
  .close-button {
    position: absolute;
    left: 0.5rem;
    top: calc(var(--ion-safe-area-top) + 0.45rem);
    z-index: 99;
    background: rgba(255, 255, 255, 0.5);
    padding: 0 !important;
    border-radius: 50%;
    width: 2.25rem;
    height: 2.25rem;
    font-size: 24px;
    color: var(--ion-color-dark);
  }
}

