@import '../../../ui/theme/breakpoints';

.headingWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.questionnaireIcon {
  display: block;
  width: 100%;
}

.textCenter {
  text-align: center;
}

.anamnesisLayout {
  :global {
    .scroll-container {
      @include lg {
        min-height: calc(100% - 78px);
      }

      &::after {
        display: none;
      }
    }

    .content-container {
      padding: 0;
      flex-grow: 1;
      @include lg {
        display: flex;
      }
    }

    //noinspection ALL
    .before-content {
      background: white;
      width: 100%;
      height: calc(4.5rem + constant(safe-area-inset-top));
      height: calc(4.5rem + env(safe-area-inset-top));
      @include lg {
        height: 100%;
        background: transparent;

      }
    }

    .action-container .return-btn {
      top: 1.25rem;
    }
  }

}

//noinspection ALL
.anamnesisContent {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-around;
  min-height: calc(100vh - (4.5rem + constant(safe-area-inset-top)));
  min-height: calc(100vh - (4.5rem + env(safe-area-inset-top)));
  @include lg {
    min-height: calc(100vh - (10.75rem + constant(safe-area-inset-top)));
    min-height: calc(100vh - (10.75rem + env(safe-area-inset-top)));
  }
}

.anamnesisFooter {
  margin-top: auto;
}

.anamnesisFormHeading {
  text-align: center;
  font-size: 1.125rem;
  font-weight: 600;
  margin-bottom: 2rem;
}

.visitReason {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  .reasonItem {
    width: 100%;
    height: 3.5rem;
    background: var(--ion-color-primary);
    padding: 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 1rem;
    font-weight: 400;

    &:hover {
      cursor: pointer;
    }

    &.checked {
      background: var(--ion-color-sand);

      &::after {
        content: "";
        display: flex;
        width: 20px;
        height: 20px;
        background: url(./images/checkIcon.svg) no-repeat;
      }
    }
  }
}

.bodyPart {
  display: flex;
  flex-direction: column;
  align-items: center;

  .bodyPartContent {
    display: grid;
    grid-template-columns: 1fr 1fr;
    justify-items: center;
    gap: 0.75rem;
    width: fit-content;
  }

  .bodyPartItem {
    height: 6.5rem;
    width: 10.25rem;
    background: var(--ion-color-primary);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: var(--ion-color-tertiary);
    position: relative;
  }

  .bodyPartIcon {
    width: 48px;
    height: 48px;
  }

  .bodyPartChecked {
    background: var(--ion-color-sand);

    &::after {
      right: 0.75rem;
      top: 0.625rem;
      content: "";
      position: absolute;
      width: 1.25rem;
      height: 1.25rem;
      background: url(./images/checkIcon.svg) no-repeat;
    }
  }
}

.hiddenCheckBox {
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}

.partOption {
  flex: 1;
  height: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--ion-color-primary);

  &::after {
    content: "";
    display: flex;
    margin-left: 0.625rem;
    width: 1.25rem;
    height: 1.25rem;
  }

  &.checked {
    background: var(--ion-color-sand);

    &::after {
      background: url(./images/checkIcon.svg) no-repeat;
    }
  }
}

.detailOptionsWrapper {
  display: flex;
  gap: 1rem;
  width: 100%;
  margin-top: 1rem;
}

.buttonLabel {
  margin: 0;
  font-size: 0.875rem;
}

.injuryDetails {
  display: flex;
  flex-direction: column;

  .injuryDetailsIcon {
    width: 7.5rem;
    height: 7.5rem;
    align-self: center;
    margin-top: 1.25rem;
    margin-bottom: 0.5rem;
  }

  .injuryDetailsHeading {
    align-self: center;
    margin-bottom: 2.75rem;
    font-weight: 600;
    font-size: 1.375rem;
  }

  .optionLabel {
    margin: 0;
    font-size: 1rem;
  }

  .sideLabel {
    margin-left: 1.25rem;
    font-size: 1rem;
  }

  .injurySubheading {
    margin-bottom: 0.5rem;
    font-size: 1rem;
    font-weight: 600;
  }

  .sideHeading {
    font-size: 1rem;
    font-weight: 600;
    margin-bottom: 0.25rem;
  }

  ion-item {
    &::part(native) {
      padding: 0;
    }
  }
}

//noinspection ALL
.successWrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  min-height: calc(100vh - (4.5rem + constant(safe-area-inset-top)));
  min-height: calc(100vh - (4.5rem + env(safe-area-inset-top)));
  @include lg {
    min-height: calc(100vh - (10.75rem + constant(safe-area-inset-top)));
    min-height: calc(100vh - (10.75rem + env(safe-area-inset-top)));
  }
}

.successIcon {
  font-size: 96px;
  display: block;
  color: var(--ion-color-secondary);
  margin: env(safe-area-inset-top, 0) auto 1rem;
  @include lg {
    margin: calc(-1rem - env(safe-area-inset-top, 0)) auto 1rem;

  }
}

.successContent {
  flex-grow: 1;
}

.successButtons {
  padding: 0.75rem;
}

.transparentBtn, .transparentBtn:hover {
  color: var(--ion-color-black);
  background: transparent;
  border-color: var(--ion-color-black);
}

.anamnesisInfo {
  padding-bottom: 0;

  .anamnesisInfoList {
    list-style-type: none;
    padding-left: 0.5rem;
    margin-bottom: 0;

    .anamnesisInfoItem {
      margin-left: 0;
      vertical-align: center;
      line-height: 1.5rem;
      display: flex;
      align-items: center;
      padding-bottom: 0.5rem;


      &::before {
        content: '✔';
        font-size: 1.25rem;
        display: block;
      }

      &::marker {
        font-size: 1.25rem;
      }

      .anamnesisInfoText {
        margin-left: 0.5rem;
        display: block;
        line-height: 1.35;
      }
    }
  }
}

// TODO reusable radio component
.checkbox {
  :global {
    ion-list {
      ion-item {
        --padding-start: 0.5rem;
        --background-activated: none;
        flex-basis: 50%;

        & > ion-radio {
          &::part(container) {
            width: 1.5rem;
          }

          &::part(mark) {
            border: 1.5px solid var(--ion-color-tertiary);
            transform: none;
            width: 1.5rem;
            height: 1.5rem;
            border-radius: 50%;
            position: relative;

            &::after {
              display: block;
              content: '';
              width: 1rem;
              height: 1rem;
              position: relative;
              left: 50%;
              top: 50%;
              transform: translate(-50%, -50%);
              opacity: 0;
              transition: opacity ease-in-out 0.25s;
              background: var(--ion-color-tertiary);
              border-radius: 50%;
            }
          }

          &::part(label) {
            display: flex;
            margin: 0 0 0 1rem;
          }

          &.radio-checked {
            &::part(mark)::after {
              opacity: 1;
            }
          }
        }
      }
    }
  }
}

.error {
  font-size: 12px;
  margin-top: 0.5rem;
  color: var(--ion-color-danger);
  margin-left: 0.75rem;
}

//noinspection ALL
.nextFooter {
  display: flex;
  flex-direction: row;
  padding: 1rem 1rem constant(safe-area-inset-bottom, 1rem);
  padding: 1rem 1rem max(1rem, env(safe-area-inset-bottom));
  align-items: center;
  @include lg {
    max-width: #{$desktop-max-width};
    width: 100%;
    margin: 0 auto;
    background: none;
  }

  .container {
    flex: 1 1;
  }
  .spacer {
    @include lg {
      flex: 0 1;
      margin-left: calc(50% + 1rem);
    }

  }
}

.imageWrapper {
  display: none;
  @include lg {
    display: block;
  }
  .image {
    height: 100%;
    object-fit: cover;
    object-position: top;
    width: 100%;
    max-width: 100%;
  }
}