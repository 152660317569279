.membership-card {
  padding: 1rem;
  .membership-title {
    padding-top: .25rem;
  }
  .membership-letter {
    white-space: pre-wrap;
  }
}
.membership-button {
  width: 100%;
}

.membership-img {
  height: 100%;
  object-fit: cover;
  object-position: top;
  width: 100%;
}