.code-input {
  text-align: center;
  font-size: 20px;
}

.conditions {
  margin-top: 0;

  .link {
    background: none;
    font-size: 1rem;
    display: inline-block;
    color: var(--ion-color-secondary);
    text-decoration: underline;
  }
}

:root {
  --ion-grid-column-padding: .6rem;
}
