@import '../../../ui/theme/breakpoints';

.booking-details-modal {
  ion-footer {
    box-shadow: none;
    //noinspection ALL
    .content {
      display: flex;
      flex-direction: column;
      padding: 0.5rem 1rem calc(1.5rem + constant(safe-area-inset-bottom, 0));
      padding: 0.5rem 1rem calc(1.5rem + env(safe-area-inset-bottom, 0));
      align-items: center;
      @include lg {
        max-width: #{$desktop-max-width};
        width: 100%;
        margin: 0 auto;
        background: none;
      }

      ion-button {
        flex: 1;
      }
    }

    .transparentBtn, .transparentBtn:hover {
      color: var(--ion-color-black);
      background: transparent;
      border-color: var(--ion-color-black);
    }
  }

  .code {
    margin-bottom: 0.75rem;
  }

  ion-grid {
    padding-bottom: 0;
  }

  .treatment_info {
    padding-bottom: 0;
    h4 {
      font-size: 1rem;
      margin: 0.5rem 0;
    }

    ul {
      list-style-type: none;
      padding-left: 0.5rem;
      margin-bottom: 0;

      li {
        margin-left: 0;
        vertical-align: center;
        line-height: 1.5rem;
        display: flex;
        align-items: center;
        padding-bottom: 0.5rem;


        &::before {
          content: '✔';
          font-size: 1.25rem;
          display: block;
        }

        &::marker {
          font-size: 1.25rem;
        }

        span {
          margin-left: 0.5rem;
          display: block;
          line-height: 1.35;
        }
      }
    }
  }
}