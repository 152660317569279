@import "../theme/breakpoints";
//noinspection All
.booking-summary-footer {
  display: flex;
  flex-direction: row;
  padding: 1rem 2rem constant(safe-area-inset-bottom, 1rem);
  padding: 1rem 2rem max(1rem, env(safe-area-inset-bottom));
  align-items: center;
  @include lg {
    max-width: #{$desktop-max-width};
    width: 100%;
    margin: 0 auto;
    background: none;
  }

  & > .date, & > .text {
    flex: 1;
    font-size: 18px;
    color: #000;
    @include lg {
      margin-left: calc(50% + 1rem);
    }
  }

  & > .next-container {
    flex: 1;

    ion-button {
      min-width: 6rem;
      width: 100%;
      @include lg {
        min-width: 8rem;
      }
    }
  }

  .text + .next-container {
    flex: 0;
  }
}

.booking-payment .booking-summary-footer {
  .text {
    flex: 0;
  }

  .next-container {
    flex: 1;
  }
}
