@import '../../theme/breakpoints';

//noinspection ALL
.header {
  margin-top: constant(safe-area-inset-top);
  margin-top: env(safe-area-inset-top);
  position: relative;
  display: flex;
  justify-content: center;
  padding-bottom: 0.75rem;

  @include lg {
    justify-content: space-between;
    width: 100%;
    padding: 0 8rem 0 6rem;
    max-width: #{$desktop-max-width};
  }

  .logo {
    display: block;
    background: url('images/logo.png') no-repeat center center;
    background-size: contain;
    height: 3.75rem;
    width: 11rem;
    text-indent: -9999px;
    cursor: pointer;
    margin-top: 0.75rem;
    @include lg {
      background: url('images/logo_horizontal.png') no-repeat center center;
      background-size: contain;
      height: 4rem;
      width: 11rem;
    }
  }

  .menu-button {
    border-radius: 50%;
    background: transparent;
    position: absolute;
    right: 1.75rem;
    top: 1.15rem;
    text-align: center;
    @include lg {
      position: relative;
      right: initial;
      top: 0.15rem;
    }

    &:focus {
      outline: none;
    }

    svg {
      color: var(--ion-color-primary-contrast);
      display: inline-block;
      font-size: 1.75rem;
      stroke: var(--ion-color-primary-contrast);
      width: 3.25rem;
      height: 3.25rem;
    }
  }
}

ion-popover {
  &::part(content) {
    border-radius: 0;

    @media (max-width: $desktop-max-width) {
      left: unset;
      right: 2rem;
    }
  }
}

.menu-popover {
  ion-item {
    --padding-start: 0;
    --inner-padding-start: 1rem;

    &:last-child {
      --border-style: none;
    }
  }
}