@import '../../../../ui/theme/breakpoints';
@import 'src/ui/elements/ExpertCard/expertCard.module';

.expertInfo {
  display: grid;
  width: 100%;
  grid-template-areas:
        "name details price"
        "rating rating price";
  padding: 0.75rem;
  grid-template-columns: max-content 0 auto;
}

.withDetails {
  grid-template-columns: max-content auto auto;
}

.name {
  grid-area: name;
  font-size: 18px;
  font-weight: 600;
  align-self: end;
}


.rating {
  align-self: start;
  grid-area: rating;
  font-size: 0.875rem;
  display: flex;
  align-items: center;
  line-height: 1;
  color: var(--ion-color-tertiary);
  padding: 0.25rem 0;

  .ratingStars {
    white-space: nowrap;
  }

  ion-icon {
    margin-left: 0.25rem;
    font-size: 0.875rem;
    color: var(--ion-color-tertiary);
    @include lg {
      font-size: 0.875rem;
    }
  }
}

.details {
  grid-area: details;
  justify-self: end;
  color: var(--ion-color-secondary);
  font-size: 0.875rem;
  background: none;
  margin: 0;
  line-height: 1.25;
  align-self: self-start;

  &:focus {
    outline: none
  }
}

.price {
  @include price;
  align-self: center;
}