.booking-card {
  background: var(--ion-color-linen);
  color: var(--ion-color-tertiary);
  padding: 1rem;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  & > .content {
    flex: 1;
    margin-right: 1rem;
    display: flex;
    flex-direction: column;

    h4 {
      margin: 0 0 0.75rem;
      font-size: 22px;
    }

    .time, .length {
      font-size: 16px;
      line-height: 1.3;
    }

    .length {
      margin-bottom: auto;
    }
  }

  .expert-info {
    text-align: center;
    display: flex;
    flex-direction: column;

    .name {
      align-self: center;
      margin-top: 0.5rem;
      font-size: 14px;
    }

    ion-avatar {
      align-self: center;
      width: 4.5rem;
      height: 4.5rem;
    }
  }

  & > .expert-info {
    .submenu {
      position: relative;
      text-align: right;
      flex: 1;

      button.more-button {;
        color: var(--ion-color-tertiary);
        font-size: 18px;
        background: none;
        height: 2.5rem;
        width: 2.5rem;
        margin-top: -0.5rem;
        margin-right: -0.5rem;

        &:focus {
          outline: none
        }
      }
    }
  }

  .avatar-wrapper {
    display: flex;
    justify-content: center;

    ion-avatar {
      z-index: 9
    }

    ion-avatar + ion-avatar {
      z-index: 1;
      margin-right: -25%;
      transform: translateX(-50%);
    }
  }
}

.past-booking-footer {
  flex-basis: 100%;
  display: flex;
  flex-direction: row;
  margin: 1rem 0;
  align-items: flex-end;
  gap: 1rem;

  .footer-btn {
    border: 1px solid var(--ion-color-tertiary);
    background: transparent;
    color: var(--ion-color-tertiary);

  }
}

.submenu-list {
  padding-bottom: 0.75rem;
}