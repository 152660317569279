.customButton {
  padding: 0 2.25rem;
  width: 100%;
  line-height: 1;
  cursor: pointer;
  background: var(--ion-color-secondary);
  border: 1px solid var(--ion-color-secondary);
  height: 2.5rem;
  font-size: 0.875rem;
  font-weight: 600;
  color: var(--ion-color-secondary-contrast);
  margin: 4px 0;

  &:hover {
    background: rgba(var(--ion-color-secondary-rgb), 0.95);
    border-color: rgba(var(--ion-color-secondary-rgb), 0.95);
    cursor: pointer;
  }
}

.disabled {
  background: none;
  color: var(--ion-color-tertiary);
  border-color: var(--ion-color-tertiary);

  &:hover {
    color: var(--ion-color-tertiary);
    border-color: var(--ion-color-tertiary);
    background: none;
  }
}

.shrink {
  width: auto;
  display: block;
  margin-left: auto;
}