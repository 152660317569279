@import 'ui/theme/breakpoints';


.login-page {
  @media (max-width: #{$screen-md-min}) {
    .before-content {
      //noinspection ALL
      .no-content {
        height: calc(4rem + constant(safe-area-inset-top));
        height: calc(4rem + env(safe-area-inset-top));
      }
    }

    ion-header .return-btn {
      top: 0.6rem;
    }

    .content-container {
      min-height: calc(100vh - 4rem - env(safe-area-inset-top));

      ion-grid {
        display: flex;
        justify-content: center;
        flex-direction: column;
      }
    }
  }
}

.sign-in-button {
  min-width: 15rem;
  height: 2.75rem;
  margin-top: 1rem;
}

.reset-password {
  font-size: 0.925rem;
  align-self: end;
  padding-top: 0;
  display: flex;
  justify-content: end;
}

.register {
  padding-top: 0;
  padding-bottom: 0;
  font-size: 0.925rem;
  display: flex;
  justify-content: center;

  .ion-link {
    margin-left: 0.5rem;
  }
}

.account-benefits {
  ul {
    list-style-type: '✔';
    padding-left: 1.5rem;

    li {
      margin-left: 0;
      vertical-align: center;
      line-height: 1.5rem;

      &::before {
        content: '';
        display: inline-block;
        height: 1em;
        width: 0.25rem;
      }

      &::marker {
        font-size: 20px;
      }
    }
  }
}

.login-footer {
  margin-left: 1rem;

  p {
    font-size: 0.875rem;
  }
}

.login-divider {
  --divider-gap: 1rem;
  width: 100%;
  display: flex;
  align-items: center;
  font-size: 1rem;
  margin-top: 1rem;
  color: var(--ion-color-medium);
  justify-content: center;

  .divider-text {
    margin: 0;
    transform: translateY(-2px);
  }

  &:before, &::after {
    content: '';
    height: 1px;
    background-color: silver;
    flex-grow: 1;
  }

  &::before {
    margin-right: var(--divider-gap);
  }

  &::after {
    margin-left: var(--divider-gap);
  }
}