.postal-code-icon {

  margin-left: 1rem;
  margin-right: 0.75rem;

  svg::after {
    transition: background-color 0.25s ease-in-out;
    background-color: var(--ion-color-white);
  }
}

.postal-code-wrapper {
  border: 1px solid var(--ion-color-tertiary);
  color: var(--ion-color-tertiary);
  background: var(--ion-color-white);
  transition: all 0.25s ease-in-out;
  cursor: pointer;
  font-size: 16px;
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 3rem;
  align-items: center;
  padding: 0.25rem 1rem;

  &.fulfilled {
    border: 1px solid var(--ion-color-linen);
    color: var(--ion-color-primary-contrast);
    background: var(--ion-color-linen);
  }

  .postal-code-input {
    input {
      --padding-start: 0
    }
  }
}

